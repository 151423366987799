import styled from 'styled-components';
import Link from 'next/link';
// components
import TextButton from 'cds/buttons/Text';
import Icon from 'cds/icons';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import { getSubCategoryList } from 'slices/category.thunk';
// styles
import { palette, zIndex } from 'cds/styles';

/**
 * 사이드 네비게이션
 * @param {object} props
 * @param {import('types/slices/category').Category[]} props.categoryList
 */

const NavItem = ({ headCategory, categoryList, hasSubNav }) => {
  const dispatch = useDispatch();

  const subCategory = useSelector(state => state.category.subCategoryList);

  const onMouseOver = category => {
    if (!hasSubNav) {
      return;
    }

    const hasOwnSubCategory = Object.prototype.hasOwnProperty.call(subCategory, category);

    if (hasOwnSubCategory) {
      return;
    }

    // 선택한 카테고리의 하위 카테고리를 fetching한다.
    dispatch(getSubCategoryList({ category }));
  };

  const List = categoryList.map((v, i) => {
    const url = hasSubNav
      ? `/category?categoryId=${v}`
      : `/category?categoryId=${headCategory}&subCategoryId=${v}`;

    return (
      <li key={v} className={v}>
        <Link href={url} passHref>
          <Button
            as="a"
            size="ExtraSmall"
            color={palette.font.primary}
            onMouseOver={() => {
              onMouseOver(v);
            }}
          >
            {v}

            {hasSubNav && (
              <Icon name="ic_arrow_right_s" width={16} height={16} fill={palette.grey060} />
            )}
          </Button>
        </Link>
        {hasSubNav && subCategory[v] && (
          <Template
            headCategory={headCategory[i]}
            categoryList={subCategory[v] ? subCategory[v].interests : []}
            hasSubNav={false}
          />
        )}
      </li>
    );
  });

  return List;
};

const Template = ({ headCategory, categoryList, hasSubNav }) => (
  <Layout>
    <ul>
      <NavItem headCategory={headCategory} categoryList={categoryList} hasSubNav={hasSubNav} />
    </ul>
  </Layout>
);

const SideNavigation = ({ categoryList }) => (
  <Template headCategory={categoryList} categoryList={categoryList} hasSubNav={true} />
);

const Layout = styled.nav`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 96px;
  left: 232px;
  width: 204px;
  height: fit-content;
  padding: 12px 0px;
  border-radius: 4px;
  background-color: ${palette.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: ${zIndex.header};

  & li {
    position: relative;
    padding: 0 4px;
  }

  & li > nav {
    display: none;
  }

  & li:hover {
    & nav {
      display: flex;
      position: absolute;
      top: -12px;
      left: 204px;
    }
  }
`;

const Button = styled(TextButton)`
  padding: 0 10px 0 20px;
  transition: 0.1s ease;
  font-size: 14px;
  justify-content: space-between;

  &:hover {
    color: ${palette.red040};

    & > svg {
      fill: ${palette.red040};
    }

    &::before {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

export default SideNavigation;
