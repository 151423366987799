import { createAsyncThunk } from '@reduxjs/toolkit';
// actions
import * as actions from 'slices/event';
// api
import * as eventAPI from 'api/event';
// utils
import { dateTimeFormatter, getKoreaDate } from 'lib/utils/format/time';

const getDateDiff = (start, end) => {
  return ((start.getTime() - end.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0) * 1;
};

/** 초저가 이벤트 호출 */
export const getMonthEvent = createAsyncThunk('event/getMonthEvent', async (_, { dispatch }) => {
  const res = await eventAPI.getMonthEvent();
  dispatch(
    actions.updateMonth({
      classList: res.list,
    }),
  );
  return Promise.resolve(true);
});

/** 이벤트 정보 리스트 호출 */
export const getEventList = createAsyncThunk('event/getEventList', async (_, { dispatch }) => {
  const res = await eventAPI.getEventList();

  dispatch(actions.updateEventList(res));
  return Promise.resolve(true);
});

/**
 * 이벤트 정보 호출
 * @param {object} data
 * @param {string} data.planId
 */
export const getEvent = createAsyncThunk('event/getEvent', async ({ planId }, { dispatch }) => {
  const res = await eventAPI.getEvent({ dealId: planId });

  if (parseInt(planId) === 1) {
    const today = getKoreaDate();
    const onlyOpenClassListForEvent = res.list.filter(
      v =>
        (getDateDiff(dateTimeFormatter(v.close_date), today) === 0 ||
          getDateDiff(dateTimeFormatter(v.close_date), today) <= 7) &&
        v,
    );

    const updateRes = Object.assign(res, { list: onlyOpenClassListForEvent });
    dispatch(actions.setPlan(updateRes));
  } else {
    dispatch(actions.setPlan(res));
  }

  return Promise.resolve(true);
});

/** 이벤트 배너 정보 호출 */
export const getEventBanner = createAsyncThunk('event/getEventBanner', async (_, { dispatch }) => {
  const res = await eventAPI.getEventBanner();
  dispatch(actions.updateEventBanner(res.event_list));
  return Promise.resolve(true);
});
