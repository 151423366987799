import request from 'api';

/* =============================== GET ================================ */

/**
 * 현재 진행 중인 초저가 이벤트 상세 정보
 * @version 3
 */
export const getMonthEvent = () => {
  return request.get(`/enfit1/api/v3/cms/deal/1`);
};

/**
 * 이벤트 상세 정보
 * @version 3
 * @param {object} props
 * @param {number} props.dealId 이벤트 ID
 */
export const getEvent = ({ dealId = 0 }) => {
  return request.get(`/enfit1/api/v3/cms/deal/${dealId}`);
};

/**
 * 현재 진행중인 이벤트 리스트 반환
 * @version 1
 */
export const getEventList = () => {
  return request.get(`/enfit2/api/v1/events`);
};

/**
 * 이벤트 배너 리스트 반환
 * @version 3
 */
export const getEventBanner = () => {
  return request.get(`/enfit1/api/v3/class_overview`, {
    headers: {
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_DEFAULT_TOKEN}`,
    },
  });
};

/* =============================== POST =============================== */

/* =============================== PUT ================================ */

/* ============================== DELETE ============================== */
